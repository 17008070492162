.Ap {
    display: flex;
    background-color: skyblue;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    width: 280px;
    margin: auto;
  }
  .reg{
    background-color: skyblue;
  }
 
  
  form{
    background-color: white;
    padding: 0px 60px;
    border-radius: 10px;
  }
  h1{
    color:rgb(77,1,77);
    text-align: center;
  }
 
  button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: rebeccapurple;
    color:white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  

.birthday{
  display: grid;
}
.input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
}

label{
    font-size: 12px;
    color: gray;
}