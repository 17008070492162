.Login {
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  .reg{
    background-color: skyblue;
    
  }
  
  .container{
    display: grid;
  }
  
  form{
    background-color: white;
    padding: 0px 60px;
    border-radius: 10px;
  }
  h1{
    color:rgb(77,1,77);
    text-align: center;
  }
 
  button{
     padding-top: 10px;
    padding-bottom: 10px;
    background-color: lightgreen;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  

.birthday{
  display: grid;
}
.input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
}

label{
    font-size: 12px;
    color: gray;
}

